import {ReactNode, useState} from "react";
import {InvoicedManuallyPurchaseOptions} from "./InvoicedManuallyPurchaseOptions";
import {PackageOffer} from "../../../functions/src/databaseSchema";
import {PayWithStripe} from "./PayWithStripe";
import {UserState} from "./LayoutState";
import {User} from "../../model/databaseSchema";
import {InvoiceItemComparison} from "../../../functions/src/RequestTypes";
import {SupplierComparisonComponent} from "../supplierComparison/SupplierComparisonComponent";


export type PaymentIsRequiredWrapperProps = {
    children: ReactNode
    pathname: string
    userState: UserState
    buyNow: (offer: PackageOffer) => void
    getComparisonForSupplierInvoice: (base64PDFString: string) => Promise<InvoiceItemComparison[] | string>
}

const noPaymentPaths = [
    "/_paymentReturn",
    "/settings"
]

const shouldUseStripe = (userConfig: User) => {
    return !!userConfig.featureFlags?.enableStripe;

}

export const PaymentIsRequiredWrapper = ({
                                             children,
                                             userState,
                                             pathname,
                                             buyNow,
                                             getComparisonForSupplierInvoice
                                         }: PaymentIsRequiredWrapperProps) => {

    const [selectedPackage, setSelectedPackage] = useState<PackageOffer | undefined>(undefined);
    const [invoicesUploaded, setInvoicesUploaded] = useState<number>(0);

    if (noPaymentPaths.includes(pathname)) {
        return <>{children}</>
    }

    console.log('userState', userState);

    // if the user is not logged in, we don't need to worry about payment
    if (userState.type === 'loading') {
        return <>{children}</>
    }

    if (userState.type === 'error') {
        return <div>Error loading user state</div>
    }

    if (userState.subscription.type !== "inactive") {
        return <>{children}</>
    }

    const useStripe = userState.userConfig?.id ? shouldUseStripe(userState.userConfig) : false;

    const buyNowWrapper = (offer: PackageOffer) => {
        if (useStripe) {
            setSelectedPackage(offer);
        } else if (offer.numberOfInvoicesNeeded) {
            setInvoicesUploaded(0);
            setSelectedPackage(offer);
        } else {
            console.log('buyNow', offer);
            buyNow(offer);
        }
    }

    const getComparisonForSupplierInvoiceWrapper = async (base64PDFString: string) => {
        if (selectedPackage && selectedPackage.numberOfInvoicesNeeded) {
            const numberOfInvoicesNeeded = selectedPackage.numberOfInvoicesNeeded;
            setInvoicesUploaded(
                (prev) => {
                    const newInvoicesUploaded = prev + 1;
                    if (newInvoicesUploaded >= numberOfInvoicesNeeded) {
                        buyNow(selectedPackage);
                    }
                    return newInvoicesUploaded;
                }
            )
        }
        return getComparisonForSupplierInvoice(base64PDFString);
    }

    if (selectedPackage && selectedPackage.numberOfInvoicesNeeded && invoicesUploaded < selectedPackage.numberOfInvoicesNeeded) {
        const callToAction = `You've compared ${invoicesUploaded} supply invoices - please compare ${selectedPackage.numberOfInvoicesNeeded - invoicesUploaded} more invoices to start your free trial.  
        Comparing invoices helps us build a database of prices that we can later use to help practices get fair deals.`;

        return <SupplierComparisonComponent getComparison={getComparisonForSupplierInvoiceWrapper}
                                            callToAction={callToAction}/>
    } else if (useStripe && selectedPackage) {
        return <><PayWithStripe selectedPackage={selectedPackage}/></>
    } else {
        return <InvoicedManuallyPurchaseOptions buyNow={buyNowWrapper} packageOffers={userState.packageOffers}
                                                useStripe={false}/>
    }
}