import {InvoiceItemComparison} from "../../../functions/src/RequestTypes";
import React, {ChangeEvent, useState} from "react";
import {v4 as uuidv4} from "uuid";
import {
    Alert,
    Button,
    Col,
    Container,
    Form,
    OverlayTrigger,
    Row,
    Spinner,
    Tab,
    Table,
    Tabs,
    Tooltip
} from "react-bootstrap";
import './SupplierComparison.css';

export type SupplierComparisonComponentProps = {
    getComparison: (base64String: string) => Promise<InvoiceItemComparison[] | string>
    callToAction?: string
}

type FileStatus = 'pending' | 'processing' | 'done' | 'error';

type FileItem = {
    id: string;
    name: string;
    base64String: string;
    status: FileStatus;
    result?: InvoiceItemComparison[];
    error?: string;
}

export const SupplierComparisonComponent = ({getComparison, callToAction}: SupplierComparisonComponentProps) => {
    const [files, setFiles] = useState<FileItem[]>([]);
    const [activeTab, setActiveTab] = useState<string | undefined>(undefined);
    const [processing, setProcessing] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.currentTarget.files;
        if (!selectedFiles) return;

        for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles[i];
            const reader = new FileReader();
            reader.onload = function (e) {
                const base64String = ((e.target?.result) as string).split(',')[1];
                setFiles(prevFiles => [
                    ...prevFiles,
                    {
                        id: uuidv4(),
                        name: file.name,
                        base64String,
                        status: 'pending'
                    }
                ]);
            };
            reader.readAsDataURL(file);
        }
    }

    const handleGetComparison = async () => {
        if (processing) return; // Prevent multiple clicks

        setProcessing(true);
        setError(undefined);

        const pendingFiles = files.filter(file => file.status === 'pending');

        const batchSize = 10;
        for (let i = 0; i < pendingFiles.length; i += batchSize) {
            const batch = pendingFiles.slice(i, i + batchSize);
            await Promise.all(batch.map(async (file) => {
                updateFileStatus(file.id, 'processing');
                try {
                    const comparison = await getComparison(file.base64String);
                    if (typeof comparison === 'string') {
                        updateFileError(file.id, comparison);
                        return;
                    } else {
                        updateFileResult(file.id, comparison);
                    }
                } catch (e: any) {
                    updateFileError(file.id, e.message);
                }
            }));
        }

        setProcessing(false);
    }

    const updateFileStatus = (id: string, status: FileStatus) => {
        setFiles(prevFiles =>
            prevFiles.map(file =>
                file.id === id ? {...file, status} : file
            )
        );
    }

    const updateFileResult = (id: string, result: InvoiceItemComparison[]) => {
        setFiles(prevFiles =>
            prevFiles.map(file =>
                file.id === id ? {...file, status: 'done', result} : file
            )
        );
    }

    const updateFileError = (id: string, error: string) => {
        setFiles(prevFiles =>
            prevFiles.map(file =>
                file.id === id ? {...file, status: 'error', error} : file
            )
        );
    }

    const getTabTitle = (file: FileItem) => {
        // first 15 characters of the file name
        return file.name.length > 15 ? file.name.substring(0, 15) + '...' : file.name;
    }

    const getLowestPrice = (item: InvoiceItemComparison) => {
        if (!item.comparison) return 0;
        return item.comparison.bottom25thPercentilePricePerUnit * item.item.numberOfUnits * item.item.quantity;
    }

    const getPossibleSavings = (item: InvoiceItemComparison) => {
        if (!item.comparison) return 0;
        return Math.max(item.item.pricePerUnit - item.comparison.bottom25thPercentilePricePerUnit, 0) * item.item.numberOfUnits * item.item.quantity;
    }

    const prettyPrintMatchKey = (genericProductKey: string, attributeKey: string) => {
        if (attributeKey === "[EMPTY_KEY]") return genericProductKey;
        const values = attributeKey.split("|").map((s) => s.split("=")[1]).join(", ");
        return `${genericProductKey}, ${values}`;
    }

    const totalPossibleSavings = files.reduce((acc, file) => {
        if (file.status !== 'done' || !file.result) return acc;
        return acc + file.result.reduce((acc, item) => acc + getPossibleSavings(item), 0);
    }, 0);

    return (
        <Container className={'my-3'}>
            <Row>
                <Col>
                    <div className={'ds-panel'}>
                        <h1 className={'ds-panel-header'}>Supplier Comparison Survey</h1>
                        {callToAction && <p className={'supplier-comparison-call-to-action'}>{callToAction}</p>}
                        <Alert variant={'info'} className={'mt-3'}>
                            <Alert.Heading>About this survey</Alert.Heading>
                            <p>
                                This page allows you to upload supplier invoices (medical, stationary or sundry) into
                                our invoice comparison survey.
                                Your
                                invoice will be aggregated with other submissions to allow us build a comparison
                                database.
                            </p>
                            <p>
                                We hope to be able to use the comparison database in the future to help you make
                                purchasing
                                decisions.
                                After you press process, invoices will be shown below in their processed form.
                            </p>
                            <p>
                                If we have enough data in our database, we'll attempt to show you a comparison for each
                                item in
                                your invoice. These comparisons will be less accurate at first, as our survey gathers
                                data, but
                                once more data is gathered, the comparisons will likely become more accurate.
                            </p>
                            <p>
                                <strong>Note:</strong> you are allowed to upload multiple invoices at once, just select
                                multiple invoice pdfs
                                in the file upload below.
                            </p>
                        </Alert>
                        <Form.Group>
                            <Form.Label>Document Upload</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange} accept=".pdf" multiple/>
                        </Form.Group>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Button className={'mt-2'}
                                disabled={processing || files.filter(f => f.status === 'pending').length === 0}
                                onClick={handleGetComparison}>
                            {processing ? 'Processing...' : 'Process'}
                        </Button>
                    </div>
                    <div className={'ds-panel mt-3'}>
                        <h2 className={'ds-panel-header'}>Comparison Results</h2>
                        <p className={'mt-3 supplier-comparison-total-potential-savings'}>Total possible
                            savings: {totalPossibleSavings.toLocaleString("en-GB", {
                                style: 'currency',
                                currency: 'GBP'
                            })}</p>
                        {files.length === 0 ? (
                            <p>No files uploaded.</p>
                        ) : (
                            <Tabs
                                activeKey={activeTab}
                                onSelect={(k) => setActiveTab(k ?? undefined)}
                                className="mb-3 mt-3"
                            >
                                {files.map(file => (
                                    <Tab eventKey={file.id} title={getTabTitle(file)} key={file.id}>
                                        {file.status === 'processing' && (
                                            <div className="d-flex justify-content-center align-items-center"
                                                 style={{height: '200px'}}>
                                                <Spinner animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            </div>
                                        )}
                                        {file.status === 'error' && (
                                            <Alert variant="warning">
                                                We had trouble processing this file. We're still improving our price
                                                comparison system and
                                                some invoices are harder to process than others. Your submission will be
                                                looked at by one of our operators
                                                to help us improve our system. If you see this message often, please
                                                contact us at <a
                                                href={"mailto:help@mypracticemanager.co.uk"}>help@mypracticemanager.co.uk</a>
                                            </Alert>
                                        )}
                                        {file.status === 'done' && file.result && (
                                            <Table striped bordered hover>
                                                <thead>
                                                <tr>
                                                    <th>Invoice Entry</th>
                                                    <th>Quantity</th>
                                                    <th>Payment Amount</th>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>
                                                        This is the best match we could find in our database,
                                                        but it might not match exactly. For example, were we matching
                                                        cars,
                                                        we might return an aggregate of all car prices, and that
                                                        wouldn't be very relevant if the car you bought was a Ferrari.
                                                    </Tooltip>}>
                                                        <th>Matched Product <i
                                                            className="bi bi-info-circle-fill supplier-comparison-tooltip"></i>
                                                        </th>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>
                                                        This is the cheapest price we could find for the matched product
                                                        in our database.
                                                    </Tooltip>}>
                                                        <th>Cheapest Survey Price <i
                                                            className="bi bi-info-circle-fill supplier-comparison-tooltip"></i>
                                                        </th>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger placement="top" overlay={<Tooltip>
                                                        This is the potential saving if you were to buy the matched
                                                        product.
                                                    </Tooltip>}>
                                                        <th>Potential Saving <i
                                                            className="bi bi-info-circle-fill supplier-comparison-tooltip"></i>
                                                        </th>
                                                    </OverlayTrigger>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {file.result.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.item.rawText}</td>
                                                        <td>{item.item.quantity}</td>
                                                        <td>{(item.item.totalPrice).toLocaleString("en-GB", {
                                                            style: 'currency',
                                                            currency: 'GBP'
                                                        })}</td>
                                                        <td>{item.comparison ? prettyPrintMatchKey(item.item.productGenericLabel, item.comparison.attributeKey) : "N/A"}</td>
                                                        <td>{item.comparison ? getLowestPrice(item).toLocaleString("en-GB", {
                                                            style: 'currency',
                                                            currency: 'GBP'
                                                        }) : "N/A"}</td>
                                                        <td>{item.comparison ? getPossibleSavings(item).toLocaleString("en-GB", {
                                                            style: 'currency',
                                                            currency: 'GBP'
                                                        }) : "N/A"}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        )}
                                        {file.status === 'pending' && (
                                            <p>Ready to process.</p>
                                        )}
                                    </Tab>
                                ))}
                            </Tabs>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    )
}