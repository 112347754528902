import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import React from "react";


export const SuppliesHomeComponent = () => {

    return <Container className={'mt-3'}>
        <Row>
            <Col xs={0} xl={1}/>
            <Col xs={12} xl={10}>
                <div className={'ds-panel'}>

                    <h1 className={'ds-panel-header'}>Supplies</h1>

                    <Container fluid className={'mt-4'}>
                        <Row>
                            <Col xs={12} lg={6} xl={4} className={'mb-3'}>
                                <Card>
                                    <i className="bi bi-diagram-2 card-icon-top"></i>
                                    <Card.Body>
                                        <Card.Title>Price Comparison</Card.Title>
                                        <Link to={`./price-comparison`}>
                                            <Button className={'w-100 mt-3'}>Compare Prices</Button>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Col>
            <Col xs={0} xl={1}/>
        </Row>
    </Container>
}



export const SuppliesHome = () => {
    return <SuppliesHomeComponent />
}