import {Button, Col, Container, Row} from "react-bootstrap";
import React from "react";
import './OnlyAvailableOnFullVersion.css';
import {Link} from "react-router-dom";
import {TextHighlight} from "./DemoCTAFreeTrial";

export type OnlyAvailableOnFullVersionProps = {
    title: string;
    description: string;
    image: string;
    altText: string;
}

export const OnlyAvailableOnFullVersion = ({title, description, image, altText} : OnlyAvailableOnFullVersionProps) => {
    return <Container>
        <Row>
            <Col>
                <div className={'ds-panel'}>
                    <h1 className={'mb-4 only-available-on-full-version-header'}>Subscribe to <TextHighlight>My</TextHighlight> Practice
                        Manager now for {title}</h1>
                    <p className={'only-available-on-full-version-description'}>{description}</p>
                    <div className={'only-available-on-full-version-image-wrapper'}>
                        <img className={'only-available-on-full-version-image'} src={image} alt={altText}/>
                    </div>

                    <h2 className={'only-available-on-full-version-free-trial-header'}>Free Trial Available</h2>
                    <p>Discover how MyPM can transform your practice management. With our <strong>30-day free
                        trial</strong>, there’s no risk in trying it out!</p>
                    <ul>
                        <li>Upload your latest 12 invoices - help build our supplier price database so we can help all
                            practices make better buying decisions in future.
                        </li>
                        <li>Customize your <strong>task dashboard</strong> to fit your unique needs.</li>
                        <li>Write risk assessments in minutes with our <strong>AI Assistant</strong>.</li>
                        <li>Gain access to our <strong>supplier price database</strong> for better cost control.</li>
                        <li>Refocus on what truly matters: building a thriving, efficient practice.</li>
                    </ul>
                    <p className={'demo-cta-free-trial-note'}>Sign up today and take the first step toward stress-free
                        practice management.</p>

                    <div className={'d-flex flex-column gap-3 align-content-center align-items-center'}>
                        <Link to="/" className={'btn btn-primary w-75'}>Start Free Trial</Link>
                    </div>
                </div>
            </Col>
        </Row>

    </Container>
}

export const OnlyAvailableOnFullVersionAssistant = () => {

    return <OnlyAvailableOnFullVersion title={"Assistant"}
                                       description={"Use our Assistant to setup automated emails, so that you never forget a task again."}
                                       image={"/images/assistant-screenshot.jpeg"}
                                       altText={"Screenshot of our Assistant options"}/>

}

export const OnlyAvailableOnFullVersionManagementTools = () => {

    return <OnlyAvailableOnFullVersion title={"Management Tools"}
                                       description={"Our management tools can help you with common tasks like writing staff emails, social media posts and business plans."}
                                       image={"/images/management-tools-screenshot.jpg"}
                                       altText={"Screenshot of our Management Tools"}/>

}

export const OnlyAvailableOnFullVersionSupplies = () => {

        return <OnlyAvailableOnFullVersion title={"Supplies"}
                                        description={"Our supplies tools can help you compare prices and make better buying decisions."}
                                        image={"/images/supplies-screenshot.jpg"}
                                        altText={"Screenshot of our Supplies Tools"}/>
}