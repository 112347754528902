import React from 'react';
import './page.css';
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {showInDevelopmentFeature} from "./model/Authentication";

export const HomePageComponent = () => {
    return <Container className={'mt-3'}>
        <Row>
            <Col xs={0} xl={1}/>
            <Col xs={12} xl={10}>
                <div className={'ds-panel'}>
                    <h1 className={'text-center'}>Welcome to&nbsp;
                        <span className="new-home-page-my-highlight-wrapper">
                        <span className="new-home-page-my-highlighter" aria-hidden="true"></span>
                        <span className="new-home-page-my-highlight-content">My</span>
                      </span>
                        &nbsp;Practice Manager</h1>

                    <p>We make practice management easier, to make more time & space for strategic improvements.</p>

                    <Container fluid className={'mt-4'}>
                        <Row>
                            <Col xs={12} lg={6} xl={4}>
                                <Card className={'new-home-page-card'}>
                                    <i className="bi bi-calendar4-week card-icon-top"></i>
                                    <Card.Body>
                                        <Card.Title>Task Manager</Card.Title>
                                        <Link to={`./task/dashboard`}>
                                            <Button className={'w-100 mt-3'}>Open Task Manager</Button>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} lg={6} xl={4}>
                                <Card className={'new-home-page-card'}>
                                    <i className="bi bi-clipboard2-check card-icon-top"></i>
                                    <Card.Body>
                                        <Card.Title>Compliance Tools</Card.Title>
                                        <Link to={`./compliance`}>
                                            <Button className={'w-100 mt-3'}>Open Compliance Tools</Button>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} lg={6} xl={4}>
                                <Card className={'new-home-page-card'}>
                                    <i className="bi bi-robot card-icon-top"></i>
                                    <Card.Body>
                                    <Card.Title>My Assistant</Card.Title>
                                        <Link to={`./assistant`}>
                                            <Button className={'w-100 mt-3'}>Open Assistant</Button>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} lg={6} xl={4}>
                                <Card className={'new-home-page-card'}>
                                    <i className="bi bi-house card-icon-top"></i>
                                    <Card.Body>
                                        <Card.Title>Management Tools</Card.Title>
                                        <Link to={`./management-tools`}>
                                            <Button className={'w-100 mt-3'}>Open Management Tools</Button>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} lg={6} xl={4}>
                                <Card className={'new-home-page-card'}>
                                    <i className="bi bi-basket card-icon-top"></i>
                                    <Card.Body>
                                        <Card.Title>Supplies</Card.Title>
                                        <Link to={`./supplies`}>
                                            <Button className={'w-100 mt-3'}>Open Supplies</Button>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Col>
            <Col xs={0} xl={1}/>
        </Row>
    </Container>
}


export const HomePage = () => {
    return <HomePageComponent/>
}