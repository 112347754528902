import {Link, Outlet, useLocation} from "react-router-dom";
import {Breadcrumb, Button, OverlayTrigger, Popover} from "react-bootstrap";
import "./DemoBreadcrumbs.css";

export const capitaliseFirstLetterOfEachWord = (str: string) => {
    return str.toLowerCase().replace(/\b\w/g, l => l.toUpperCase()).replace("'S", "'s");
}

const popover = (
    <Popover id="subscribe-now-popover">
        <Popover.Header as="h3">We're offering a free trial</Popover.Header>
        <Popover.Body>
            <ul>
                <li>Upload your latest 12 invoices for supplies (medical, stationary or sundry) and we'll give you a 30 day free trial.</li>
                <li>Help us to build a supplier price database so we can guide PMs on what they should be paying.</li>
                <li>Task tracking - never forget a key practice task again.</li>
                <li>Unlimited usage of our compliance tools.</li>
                <li>Automated emails & reports.</li>
            </ul>
            <Link to={'/'} className={'btn btn-primary w-100'}>Start Trial Now</Link>
        </Popover.Body>
    </Popover>
);

export const DemoBreadcrumbs = () => {
    const path = useLocation().pathname;

    const pathElements = path.split('/').filter((el) => el !== '');

    return <div className={'w-100'}>
        <Breadcrumb className={'mx-3 mt-1'}>
            <Breadcrumb.Item href="/demo">Demo</Breadcrumb.Item>
            {pathElements.length > 1 && pathElements[1] === 'task-manager' &&
                <Breadcrumb.Item active={path === '/demo/task-manager'} href={'/demo/task-manager'}>Task
                    Manager</Breadcrumb.Item>}
            {pathElements.length > 1 && pathElements[1] === 'compliance' &&
                <Breadcrumb.Item active={path === '/demo/compliance'}
                                 href={'/demo/compliance'}>Compliance</Breadcrumb.Item>}
            {pathElements.length > 2 && <Breadcrumb.Item active={pathElements.length === 3}
                                                         href={pathElements.slice(0, 3).join("/")}>{capitaliseFirstLetterOfEachWord(pathElements[2].replace("-", " "))}</Breadcrumb.Item>}
        </Breadcrumb>
        <Outlet></Outlet>
        <OverlayTrigger trigger="click" placement="left" overlay={popover}>
            <div className={'demo-subscribe-now-floating-button'}>
                Free Trial?
            </div>
        </OverlayTrigger>


    </div>
}