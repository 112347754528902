import React, {useState} from "react";
import {httpsCallable} from "firebase/functions";
import {functions} from "../model/firebaseConnection";
import {
    DemoAPIResponse,
    DemoGeneratePDFRequest,
    DemoGenerateTemplateDocumentRequest, DemoModifyTemplateDocumentRequest,
} from "../model/RequestTypes";
import {Alert} from "react-bootstrap";
import {downloadBase64String} from "../components/compliance/fileDownload";
import {
    AIDocumentEditor,
    GenerateDocumentProps,
    ModifyExistingDocProps
} from "../components/compliance/AIDocumentEditor";
import {DemoCTAStress} from "./DemoCTAStress";
import {DemoCTAFreeTrial} from "./DemoCTAFreeTrial";



export type DemoAIDocumentCreatorProps = {
    toolType: string,
    hideNameInput?: boolean
}


export const DemoAIDocumentCreator = ({toolType, hideNameInput} : DemoAIDocumentCreatorProps) => {

    const [error, setError] = useState<string | undefined>(undefined);


    const generateDocument = async ({documentName, documentTemplate, input} : GenerateDocumentProps) => {
        setError(undefined);
        try {
            const apiFunc = httpsCallable<DemoGenerateTemplateDocumentRequest, DemoAPIResponse>(functions, 'demoAPI');
            const response = await apiFunc({
                type: 'generateTemplateDocument',
                templateName: documentTemplate,
                documentName: documentName,
                userInput: input
            });
            console.log('response', response);
            if (response.data.type === 'error') {
                setError(response.data.error);
            }
            if (response.data.type !== "generateTemplateDocument") {
                throw new Error('Unexpected response from server');
            }
            return response.data.documentMD;
        } catch (e: any) {
            setError(e.message);
            return ""
        }
    }

    const modifyDocument = async ({documentName, documentTemplate, input, originalDocument} : ModifyExistingDocProps) => {
        setError(undefined);
        try {
            const apiFunc = httpsCallable<DemoModifyTemplateDocumentRequest, DemoAPIResponse>(functions, 'demoAPI');
            const response = await apiFunc({
                type: 'modifyTemplateDocument',
                templateName: documentTemplate,
                documentName: documentName,
                modificationRequest: input,
                documentMD: originalDocument
            });
            console.log('response', response);
            if (response.data.type === 'error') {
                setError(response.data.error);
            }
            if (response.data.type !== "modifyTemplateDocument") {
                throw new Error('Unexpected response from server');
            }
            return response.data.documentMD;
        } catch (e: any) {
            setError(e.message);
            return ""
        }
    }

    const downloadPDF = async (documentMD: string,
                                                        fileName: string,
                                                        templateName: string) => {

        setError(undefined);
        try {
            const apiFunc = httpsCallable<DemoGeneratePDFRequest, DemoAPIResponse>(functions, 'demoAPI');
            const response = await apiFunc({
                type: 'generatePDF',
                markdown: documentMD,
                templateName
            });
            if (response.data.type === 'error') {
                setError(response.data.error);
            }
            if (response.data.type !== "generatePDF") {
                throw new Error('Unexpected response from server');
            }
            downloadBase64String(response.data.pdfBase64, 'application/pdf', fileName + '.pdf');
        } catch (e: any) {
            setError(e.message);
        }
    }


    return <div className={'w-100 mb-5'}>
        {error &&
            <Alert variant={'danger'}>ERROR: {error}</Alert>
        }
        <AIDocumentEditor generateDocument={generateDocument}
                          downloadPDF={downloadPDF}
                          templateName={toolType}
                          modifyDocument={modifyDocument}
                          hideNameInput={hideNameInput}
        />
        <DemoCTAFreeTrial timeout={20000}/>
    </div>


}