import {Button, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {logEvent} from "firebase/analytics";
import {googleAnalytics} from "../model/firebaseConnection";
import "./DemoCTAFreeTrial.css";
import {Link} from "react-router-dom";

export const TextHighlight = ({children}: { children: string }) => {
    return <span className="text-highlight-wrapper">
                        <span className="text-highlighter" aria-hidden="true"></span>
                        <span className="text-highlight-content">{children}</span>
                      </span>
}

export type DemoCTAFreeTrialProps = {
    timeout: number
}

export const DemoCTAFreeTrial = ({timeout}: DemoCTAFreeTrialProps) => {

    const [showCTA, setShowCTA] = useState(false);
    const [ctaShown, setCtaShown] = useState(false);

    useEffect(() => {
        if (!ctaShown) {
            setTimeout(() => {
                    setShowCTA(true)
                    setCtaShown(true);
                    logEvent(googleAnalytics, 'demo_cta_free_trial_shown', {});
                }
                , timeout);
        }
    }, []);

    return <Modal show={showCTA} onHide={() => {
    }} centered size={"lg"} contentClassName={'demo-cta-free-trial'}>
        <div>
            <h2 className={'mb-4'}>Simplify Your Practice with <TextHighlight>My</TextHighlight> Practice Manager</h2>
            <p>Discover how MyPM can transform your practice management. With our <strong>30-day free trial</strong>, there’s no risk in trying it out!</p>
            <ul>
                <li>Upload your latest 12 invoices - help build our supplier price database so we can help all practices make better buying decisions in future.</li>
                <li>Customize your <strong>task dashboard</strong> to fit your unique needs.</li>
                <li>Write risk assessments in minutes with our <strong>AI Assistant</strong>.</li>
                <li>Gain access to our <strong>supplier price database</strong> for better cost control.</li>
                <li>Refocus on what truly matters: building a thriving, efficient practice.</li>
            </ul>
            <p className={'demo-cta-free-trial-note'}>Sign up today and take the first step toward stress-free practice management.</p>

            <div className={'d-flex flex-column gap-3 align-content-center align-items-center'}>
                <Link to="/" className={'btn btn-primary w-75'}>Start Free Trial</Link>
                <Button variant={'outline-dark'} className={'w-75'} onClick={() => setShowCTA(false)}>Continue Demo</Button>
            </div>
        </div>

    </Modal>
}