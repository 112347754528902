import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import React from "react";


export const ManagementHomeComponent = () => {

    return <Container className={'mt-3'}>
        <Row>
            <Col xs={0} xl={1}/>
            <Col xs={12} xl={10}>
                <div className={'ds-panel'}>

                    <h1 className={'ds-panel-header'}>Management Tools</h1>

                    <Container fluid className={'mt-4'}>
                        <Row>
                            <Col xs={12} lg={6} xl={4} className={'mb-3'}>
                                <Card>
                                    <i className="bi bi-envelope-check card-icon-top"></i>
                                    <Card.Body>
                                        <Card.Title>Staff Email Writer</Card.Title>
                                        <Link to={`./staff-email`}>
                                            <Button className={'w-100 mt-3'}>Write Staff Email</Button>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} lg={6} xl={4} className={'mb-3'}>
                                <Card>
                                    <i className="bi bi-postcard card-icon-top"></i>
                                    <Card.Body>
                                        <Card.Title>Social Media Post</Card.Title>
                                        <Link to={`./social-media-post`}>
                                            <Button className={'w-100 mt-3'}>Write Social Media Post</Button>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} lg={6} xl={4} className={'mb-3'}>
                                <Card>
                                    <i className="bi bi-clipboard2-data card-icon-top"></i>
                                    <Card.Body>
                                        <Card.Title>Business Plan</Card.Title>
                                        <Link to={`./business-plan`}>
                                            <Button className={'w-100 mt-3'}>Create Business Plan</Button>
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Col>
            <Col xs={0} xl={1}/>
        </Row>
    </Container>
}



export const ManagementToolsHome = () => {
    return <ManagementHomeComponent />
}