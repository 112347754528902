import React, {useEffect} from 'react';
import './App.css';
import Layout from './layout';
import {createBrowserRouter, Navigate, RouterProvider, useLocation} from "react-router-dom";
import {GuidesLayout} from "./guides/layout";
import {init} from "./model/firebaseConnection";
import {HomePage} from "./page";
import {GuidesPage} from "./guides/guidesPage";
import {GuidePage} from "./guides/guidePage";
import {SettingsLayout} from "./settings/layout";
import {SettingsPage} from "./settings/page";
import {OnboardingPage} from "./onboarding/page";
import {DemoPage} from "./demo/page";
import {AuthenticationAction} from "./components/authentication/AuthenticationAction";
import {PaymentReturn} from "./components/layout/PaymentReturn";
import {ComplianceLayout} from "./compliance/layout";
import {SearchResultsPage} from "./search/SearchResultsPage";
import {AddTaskPage} from "./tasks/addTask";
import {ViewTaskPage} from "./tasks/viewTask";
import {DemoAIDocumentCreator} from "./demo/DemoAIDocumentCreator";
import {TaskDashboard} from "./tasks/TaskDashboard";
import {AIComplianceToolPage} from "./compliance/AIComplianceToolPage";
import {ComplianceHome} from "./compliance/ComplianceHome";
import {DemoComplianceHome} from "./demo/DemoComplianceHome";
import {DemoLandingPage} from "./demo/DemoLandingPage";
import {DemoBreadcrumbs} from "./demo/DemoBreadcrumbs";
import {AssistantHome} from "./assistant/AssistantHome";
import {MyPMNavBar} from "./components/layout/MyPMNavBar";
import {EmailsPage} from "./assistant/EmailsPage";
import {AssistantLayout} from "./assistant/layout";
import {AIGeneratedEmailsPage} from "./assistant/AIEmailsPage";
import {CQCAreComingPage} from "./cqc/CQCAreComingPage";
import {CQCAuditSectionPage} from "./cqc/CQCAuditSectionPage";
import {ProcessingSensitiveDataPage} from "./privacy/ProcessingSensitiveData";
import {MonthlyTracker} from "./finance/MonthlyTracker";
import {ManagementToolsHome} from "./management-tools/ManagementToolsHome";
import {ManagementToolsLayout} from "./management-tools/layout";
import {SuppliesLayout} from "./supplies/layout";
import {PriceComparisonScreen} from "./supplies/PriceComparisonScreen";
import {SuppliesHome} from "./supplies/SuppliesHome";
import {
    OnlyAvailableOnFullVersionAssistant, OnlyAvailableOnFullVersionManagementTools, OnlyAvailableOnFullVersionSupplies
} from "./demo/OnlyAvailableOnFullVersion";

init();


const ErrorPage = () => {
    const pathname = useLocation().pathname;

    return (
        <>
            <MyPMNavBar pathname={pathname} userState={{type: "loading"}}/>
            <div className={'_404'}>

                <h3>Page Not Found</h3>
                <a href={'/'}>Return to Home</a>
            </div>
        </>
    )
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout></Layout>,
        errorElement: <ErrorPage></ErrorPage>,
        children: [
            {
                path: "/",
                element: <HomePage></HomePage>,

            },
            {
                path: "/onboarding",
                element: <Navigate to={"/"}></Navigate>
            },
            {
                path: "/_auth",
                element: <AuthenticationAction></AuthenticationAction>
            },
            {
                path: "/_paymentReturn",
                element: <PaymentReturn></PaymentReturn>
            },
            {
                path: "/task/dashboard",
                element: <TaskDashboard></TaskDashboard>
            },
            {
                path: "/finance/monthly-tracker",
                element: <MonthlyTracker></MonthlyTracker>
            },
            {
                path: "/guides",
                element: <GuidesLayout></GuidesLayout>,
                children: [
                    {
                        path: "/guides",
                        element: <GuidesPage></GuidesPage>
                    },
                    {
                        path: "/guides/:guideId",
                        element: <GuidePage></GuidePage>
                    }
                ]
            },
            {
                path: "/compliance",
                element: <ComplianceLayout></ComplianceLayout>,
                children: [
                    {
                        path: "/compliance",
                        element: <ComplianceHome></ComplianceHome>
                    },
                    {
                        path: "/compliance/risk-assessment",
                        element: <AIComplianceToolPage toolType={'Risk Assessment'}></AIComplianceToolPage>
                    },
                    {
                        path: "/compliance/policy",
                        element: <AIComplianceToolPage toolType={'Policy'}></AIComplianceToolPage>
                    },
                    {
                        path: "/compliance/complaint",
                        element: <AIComplianceToolPage toolType={'Complaint Response'} hideNameInput></AIComplianceToolPage>
                    },
                    {
                        path: "/compliance/support-letter",
                        element: <AIComplianceToolPage toolType={'Supporting Letter'} hideNameInput></AIComplianceToolPage>
                    },
                    {
                        path: "/compliance/significant-event-analysis",
                        element: <AIComplianceToolPage toolType={'Significant Event Analysis'}></AIComplianceToolPage>
                    },
                    {
                        path: "/compliance/sop",
                        element: <AIComplianceToolPage toolType={'SOP'}></AIComplianceToolPage>
                    },
                ]
            },
            {
                path: "/management-tools",
                element: <ManagementToolsLayout></ManagementToolsLayout>,
                children: [
                    {
                        path: "/management-tools",
                        element: <ManagementToolsHome></ManagementToolsHome>
                    },
                    {
                        path: "/management-tools/staff-email",
                        element: <AIComplianceToolPage toolType={"Staff Email"} hideNameInput></AIComplianceToolPage>
                    },
                    {
                        path: "/management-tools/social-media-post",
                        element: <AIComplianceToolPage toolType={"Social Media Post"} hideNameInput></AIComplianceToolPage>
                    },
                    {
                        path: "/management-tools/business-plan",
                        element: <AIComplianceToolPage toolType={"Business Plan"}></AIComplianceToolPage>
                    },
                    {
                        path: "/management-tools/staff-rota",
                        element: <AIComplianceToolPage toolType={"Staff Rota Generator"}></AIComplianceToolPage>
                    }
                ]
            },
            {
                path: "/supplies",
                element: <SuppliesLayout></SuppliesLayout>,
                children: [
                    {
                        path: "/supplies",
                        element: <SuppliesHome></SuppliesHome>
                    },
                    {
                        path: "/supplies/price-comparison",
                        element: <PriceComparisonScreen></PriceComparisonScreen>
                    },
                ]
            },
            {
                path: "/assistant",
                element: <AssistantLayout></AssistantLayout>,
                children: [
                    {
                        path: "/assistant",
                        element: <AssistantHome></AssistantHome>
                    },
                    {
                        path: "/assistant/email",
                        element: <EmailsPage></EmailsPage>
                    },
                    {
                        path: "/assistant/ai-generated-email",
                        element: <AIGeneratedEmailsPage></AIGeneratedEmailsPage>
                    },
                ]
            },

            {
                path: "/settings",
                element: <SettingsLayout></SettingsLayout>,
                children: [
                    {
                        path: "/settings",
                        element: <SettingsPage></SettingsPage>
                    }
                ]
            },
            {
                path: "/search",
                element: <SearchResultsPage></SearchResultsPage>
            },
            {
                path: "/task/onboarding",
                element: <OnboardingPage></OnboardingPage>
            },
            {
                path: "/cqc/imminent-inspection",
                element: <CQCAreComingPage></CQCAreComingPage>
            },
            {
                path: "/cqc/imminent-inspection/:subjectName",
                element: <CQCAuditSectionPage></CQCAuditSectionPage>
            },
            {
                path: "/dental",
                element: <OnboardingPage></OnboardingPage>
            },
            {
                path: "/data/sensitive-data-guide",
                element: <ProcessingSensitiveDataPage></ProcessingSensitiveDataPage>
            },
            {
                path: "/demo",
                element: <DemoBreadcrumbs/>,
                children: [
                    {
                        path: "/demo",
                        element: <DemoLandingPage></DemoLandingPage>
                    },
                    {
                        path: "/demo/task-manager",
                        element: <DemoPage></DemoPage>
                    },
                    {
                        path: "/demo/compliance",
                        element: <DemoComplianceHome></DemoComplianceHome>
                    },
                    {
                        path: "/demo/risk-assessment",
                        element: <Navigate to="/demo"></Navigate>
                    },
                    {
                        path: "/demo/compliance/risk-assessment",
                        element: <DemoAIDocumentCreator toolType={'Risk Assessment'}></DemoAIDocumentCreator>
                    },
                    {
                        path: "/demo/compliance/policy",
                        element: <DemoAIDocumentCreator toolType={'Policy'}></DemoAIDocumentCreator>
                    },
                    {
                        path: "/demo/compliance/complaint",
                        element: <DemoAIDocumentCreator toolType={'Complaint Response'} hideNameInput></DemoAIDocumentCreator>
                    },
                    {
                        path: "/demo/compliance/support-letter",
                        element: <DemoAIDocumentCreator toolType={'Supporting Letter'} hideNameInput></DemoAIDocumentCreator>
                    },
                    {
                        path: "/demo/compliance/significant-event-analysis",
                        element: <DemoAIDocumentCreator toolType={'Significant Event Analysis'}></DemoAIDocumentCreator>
                    },
                    {
                        path: "/demo/compliance/sop",
                        element: <DemoAIDocumentCreator toolType={'SOP'}></DemoAIDocumentCreator>
                    },
                    {
                        path: "/demo/assistant",
                        element: <OnlyAvailableOnFullVersionAssistant/>
                    },
                    {
                        path: "/demo/management-tools",
                        element: <OnlyAvailableOnFullVersionManagementTools/>
                    },
                    {
                        path: "/demo/supplies",
                        element: <OnlyAvailableOnFullVersionSupplies/>
                    }
                ]
            },
            {
                path: "/task/add",
                element: <AddTaskPage></AddTaskPage>
            },
            {
                path: "/task/view/:taskId",
                element: <ViewTaskPage></ViewTaskPage>
            },
            {
                path: "/404",
                element: <ErrorPage></ErrorPage>
            }
        ]
    }
]);


function App() {
    useEffect(() => {
        if (window?.Cypress) {
            return;
        }
        const script = document.createElement('script');

        const heapProject = process.env.REACT_APP_HEAP_PROJECT_ID;

        if (!heapProject || heapProject === "none") {
            return;
        }

        script.innerText = `
            window.heapReadyCb = window.heapReadyCb || [], window.heap = window.heap || [], heap.load = function (e, t){window.heap.envId = e,window.heap.clientConfig=t=t||{},window.heap.clientConfig.shouldFetchServerConfig=!1;var a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src="https://cdn.us.heap-api.com/config/"+e+"/heap_config.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(a,r);var n=["init","startTracking","stopTracking","track","resetIdentity","identify","getSessionId","getUserId","getIdentity","addUserProperties","addEventProperties","removeEventProperty","clearEventProperties","addAccountProperties","addAdapter","addTransformer","addTransformerFn","onReady","addPageviewProperties","removePageviewProperty","clearPageviewProperties","trackPageview"],i=function(e){return function(){var t=Array.prototype.slice.call(arguments,0);window.heapReadyCb.push({name:e,fn:function(){heap[e]&&heap[e].apply(heap,t)}})}};for(var p=0;p<n.length;p++)heap[n[p]]=i(n[p])};
            heap.load("${heapProject}");
        `;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);
    return (
        <RouterProvider router={router}/>
    );
}

export default App;
