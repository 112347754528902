import {SupplierComparisonComponent} from "../components/supplierComparison/SupplierComparisonComponent";
import {
    GetComparisonForSupplierInvoiceRequest,
    GetComparisonForSupplierInvoiceResponse,
    InvoiceItemComparison
} from "../../functions/src/RequestTypes";
import {callAPI} from "../model/API";


export const PriceComparisonScreen = () => {
    const getComparisonForSupplierInvoice = async (base64PDFString: string) : Promise<InvoiceItemComparison[] | string> => {
        try {
            const response = await callAPI<GetComparisonForSupplierInvoiceRequest, GetComparisonForSupplierInvoiceResponse>({
                type: 'getComparisonForSupplierInvoice',
                invoicePDFBase64: base64PDFString
            });
            return response.comparisonResults ?? 'No comparison results found';
        } catch (e: any){
            return e.message || 'Unknown error occurred';
        }
    }
    return <SupplierComparisonComponent getComparison={getComparisonForSupplierInvoice} />
}