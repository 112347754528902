import {Outlet, useLocation} from "react-router-dom";
import {Breadcrumb} from "react-bootstrap";


export const ManagementToolsLayout = () => {

    const path = useLocation().pathname;

    const pathElements = path.split('/').filter((el) => el !== '');

    return <div className={'w-100'}>
        <Breadcrumb className={'mx-3 mt-1'}>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            {pathElements.length > 0 && <Breadcrumb.Item active={path === '/management-tools'} href={'/management-tools'}>Management Tools</Breadcrumb.Item>}
            {path.startsWith('/management-tools/staff-email') &&
                <Breadcrumb.Item active={path === '/management-tools/staff-email'} href={'/management-tools/staff-email'}>Staff Email</Breadcrumb.Item>
            }
            {path.startsWith('/management-tools/social-media-post') &&
                <Breadcrumb.Item active={path === '/management-tools/social-media-post'} href={'/management-tools/social-media-post'}>Social Media Post</Breadcrumb.Item>
            }
            {path.startsWith('/management-tools/business-plan') &&
                <Breadcrumb.Item active={path === '/management-tools/business-plan'} href={'/management-tools/business-plan'}>Business Plan</Breadcrumb.Item>
            }
        </Breadcrumb>
        <Outlet></Outlet>
    </div>
}