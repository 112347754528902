import {Outlet, useLocation} from "react-router-dom";
import {Breadcrumb} from "react-bootstrap";


export const SuppliesLayout = () => {

    const path = useLocation().pathname;

    const pathElements = path.split('/').filter((el) => el !== '');

    return <div className={'w-100'}>
        <Breadcrumb className={'mx-3 mt-1'}>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            {pathElements.length > 0 && <Breadcrumb.Item active={path === '/supplies'} href={'/supplies'}>Supplies</Breadcrumb.Item>}
            {path.startsWith('/supplies/price-comparison') &&
                <Breadcrumb.Item active={path === '/supplies/price-comparison'} href={'/supplies/price-comparison'}>Price Comparison</Breadcrumb.Item>
            }
        </Breadcrumb>
        <Outlet></Outlet>
    </div>
}